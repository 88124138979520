import client from '../../apollo-client';
import { GET_PACKS } from './packs.graphql';

export async function getPacksService(): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_PACKS,
    });

    return data.listAvailableOffers.packs;
  } catch (error) {
    console.error('An error occurred while fetching packs:', error);
    throw error;
  }
}
