export const ERROR_CODES = {
  LOGIN_FAILED: 'server_errors.LOGIN_FAILED',
  UNEXPECTED_ERROR: 'server_errors.UNEXPECTED_ERROR',
  INTERNAL_SERVER_ERROR: 'server_errors.INTERNAL_SERVER_ERROR',
  BAD_REQUEST: 'server_errors.LOGIN_FAILED',
};

export function getErrorMessage(
  errorCode: keyof typeof ERROR_CODES | string | null,
  t: (key: string) => string
): string | null {
  if (
    errorCode &&
    Object.prototype.hasOwnProperty.call(ERROR_CODES, errorCode)
  ) {
    const translationKey = ERROR_CODES[errorCode as keyof typeof ERROR_CODES];
    return t(translationKey);
  }
  return t('server_errors.UNEXPECTED_ERROR');
}
