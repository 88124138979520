import client from '../../apollo-client';
import { User } from '../../types/feelbat';
import { ALL_USERS, GET_USER, REMOVE_USER, UPDATE_USER } from './user.graphql';

export interface UpdateUserInput {
  id: string;
  password?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  country?: string;
  phone_number?: string;
  failed_login_attempts?: number;
  is_locked?: boolean;
  email_verified?: boolean;
  offers_id?: string;
  offer_exp?: string;
  role?: string;
  permissions?: string[];
  token_linkfeel?: number;
  token_linkfeel_used?: number;
}

export async function getUserService(id: string): Promise<User> {
  try {
    const { data } = await client.query({
      query: GET_USER,
      variables: { id },
    });

    return data.user;
  } catch (error) {
    console.error('An error occurred while fetching the user:', error);
    throw error;
  }
}

export async function updateUserService(
  updateUserInput: UpdateUserInput
): Promise<User> {
  try {
    const { data } = await client.mutate({
      mutation: UPDATE_USER,
      variables: { updateUserInput },
    });

    return data.updateUser;
  } catch (error) {
    console.error('An error occurred while updating the user:', error);
    throw error;
  }
}

export async function getAllUsersService(): Promise<User[]> {
  try {
    const { data } = await client.query({
      query: ALL_USERS,
    });

    return data.getAllUsersWithActivePack;
  } catch (error) {
    console.error('An error occurred while fetching all the users:', error);
    throw error;
  }
}

/**
 * Removes a user by their user ID.
 *
 * This function sends a GraphQL mutation request to remove a user from the system.
 * If an error occurs during the request, it logs the error and rethrows it.
 *
 * @param userId - The ID of the user to be removed.
 * @throws {Error} Throws an error if the request fails.
 * @returns {Promise<void>} A promise that resolves when the user has been successfully removed.
 */
export async function removeUser(userId: string): Promise<void> {
  try {
    await client.mutate({
      mutation: REMOVE_USER,
      variables: {
        id: userId,
      },
    });
  } catch (error) {
    console.error('An error occurred while removing user:', error);
    throw error;
  }
}
