import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

function useAuth() {
  const { isLoading, accessToken, refreshToken, role } = useSelector(
    (state: RootState) => state.auth
  );

  // Here, user is considered authenticated if accessToken exists
  const isAuthenticated = !!accessToken;

  return { isLoading, isAuthenticated, accessToken, refreshToken, role };
}

export default useAuth;
