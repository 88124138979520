import { ServerError } from '@apollo/client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  UpdateUserInput,
  getUserService,
  updateUserService,
} from './user.service';
import { handleGraphQLError } from '../../utils/errorUtils';

export const fetchUserById = createAsyncThunk<
  any,
  string, // ID as payload
  { rejectValue: ServerError; state: RootState }
>('user/fetchUserById', async (id, thunkApi) => {
  try {
    const user = await getUserService(id);
    return user;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError as any);
  }
});

export const updateUserById = createAsyncThunk<
  any,
  { updateUserInput: UpdateUserInput },
  { rejectValue: ServerError; state: RootState }
>('user/updateUserById', async ({ updateUserInput }, thunkApi) => {
  try {
    const updatedUser = await updateUserService(updateUserInput);
    return updatedUser;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError as any);
  }
});
