import { gql } from '@apollo/client';

export const GET_OFFERS = gql`
  query GET_OFFERS {
    listAvailableOffers {
      offers {
        id
        title
        description
        price
      }
      packs {
        id
        title
        description
        price
        offers_include {
          id
          title
          description
        }
      }
    }
  }
`;

export const GET_CURRENT_OFFERS = gql`
  query listSubscribedOffers($user_id: String!) {
    listSubscribedOffers(user_id: $user_id) {
      offers {
        id
        title
        description
        price
      }
      packs {
        id
        title
        description
        price
        offers_include {
          id
          title
          description
        }
      }
      active_pack {
        id
        title
        description
        price
        offers_include {
          id
          title
          description
        }
        subscribed_at
        subscribe_end
      }
    }
  }
`;
