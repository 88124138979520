import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ERROR_CODES } from '../../utils/errorCodes';
import { fetchOffers, getCurrentOffers } from './offers.thunk';

interface OffersState {
  isLoading: boolean;
  errorCode: keyof typeof ERROR_CODES | string | null;
  offers: any | null;
  packs?: any | null;
}

const initialState: OffersState = {
  isLoading: false,
  errorCode: null,
  offers: null,
  packs: null,
};

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCurrentOffers.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        getCurrentOffers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.offers = action.payload;
        }
      )
      .addCase(
        getCurrentOffers.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
        }
      )
      .addCase(fetchOffers.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(fetchOffers.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.offers = action.payload;
      })
      .addCase(fetchOffers.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
      });
  },
});

export default offersSlice.reducer;
