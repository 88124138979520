import { MeasureTypeEnum } from '../enums/feelbat.enum';
import { SelectedSensor } from './pdfReport/types';

export const findExistingModuleIndex = (
  selectedSensorsComparisons: any[],
  updatedComparison: any
) => {
  return selectedSensorsComparisons.findIndex(
    (module) =>
      module.moduleId === updatedComparison.moduleId &&
      module.family === updatedComparison.family
  );
};

export const updateExistingModule = (
  existingModule: any,
  updatedComparison: any
) => {
  const existingSensors = existingModule.sensors;

  updatedComparison.sensors.forEach((newSensor: any) => {
    const sensorIndex = existingSensors.findIndex(
      (sensor: any) => sensor.sensorId === newSensor.sensorId
    );

    if (sensorIndex !== -1) {
      existingSensors[sensorIndex].selectedCurves = newSensor.selectedCurves;
    } else {
      existingSensors.push(newSensor);
    }
  });
};

export const updateTemperatureLines = (
  selectedSensors: SelectedSensor[],
  uncheckModules: string[]
) => {
  return selectedSensors.map((sensor) => {
    if (uncheckModules.includes(sensor.moduleId!)) {
      return {
        ...sensor,
        selectedCurves: sensor.selectedCurves?.filter(
          (curve) => curve !== MeasureTypeEnum.TEMPERATURE
        ),
      };
    } else {
      return {
        ...sensor,
        selectedCurves: [
          ...new Set([...sensor.selectedCurves!, MeasureTypeEnum.TEMPERATURE]),
        ],
      };
    }
  });
};
