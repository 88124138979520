import { createTheme, responsiveFontSizes } from '@mui/material/styles';

/**
 * Defines a color set with main, light, dark, and contrast text variations.
 */
interface ColorSet {
  main: string;
  light?: string;
  dark?: string;
  contrastText?: string;
}

/**
 * Describes custom colors including primary, secondary, tertiary, error, success, and warning color sets.
 */
interface CustomColors {
  primary: ColorSet;
  secondary: ColorSet;
  tertiary: ColorSet;
  error: ColorSet;
  success: ColorSet;
  warning?: ColorSet;
}

/**
 * Extends the MUI Theme and ThemeOptions to include custom colors.
 */
declare module '@mui/material/styles' {
  interface Theme {
    custom: { colors: CustomColors };
  }

  interface ThemeOptions {
    custom: { colors: CustomColors };
  }
}

/**
 * Default color values used as a fallback.
 */
const defaultColors: CustomColors = {
  primary: {
    main: '',
  },
  secondary: {
    main: '',
  },
  tertiary: {
    main: '',
  },
  error: {
    main: '',
  },
  success: {
    main: '',
  },
};

/**
 * Creates a custom theme with the specified mode and custom colors.
 *
 * @param mode - Theme mode, either 'light' or 'dark'.
 * @param customColors - Custom color definitions.
 * @returns - A responsive theme with custom colors.
 */
const createCustomTheme = (
  mode: 'light' | 'dark',
  customColors: CustomColors
) => {
  const theme = createTheme({
    palette: { mode },
    typography: {
      fontFamily: 'Montserrat, sans-serif',
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 700,
      fontWeightBold: 900,
    },
    custom: { colors: { ...defaultColors, ...customColors } },
  });

  return responsiveFontSizes(theme);
};

/**
 * Light theme configuration.
 */
export const lightTheme = createCustomTheme('light', {
  primary: {
    main: '#2468C1',
    light: '#4f8dd6',
    dark: '#0f5a96',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#E85319',
    light: '#ff6f40',
    dark: '#b14000',
    contrastText: '#000000',
  },
  tertiary: {
    light: '#F7F7F9',
    main: '#363534',
    dark: '#121211',
    contrastText: '#DCE2ED',
  },
  error: {
    main: '#f44336',
    light: '#ff7961',
    dark: '#ba000d',
    contrastText: '#000000',
  },
  success: {
    main: '#39B4A3',
    light: '#6ceac4',
    dark: '#008a75',
    contrastText: '#000000',
  },
  warning: {
    main: '#ff9800',
    light: '#ffc046',
    dark: '#c66900',
    contrastText: '#000000',
  },
});

/**
 * Dark theme configuration.
 */
export const darkTheme = createCustomTheme('dark', {
  primary: {
    main: '#3f51b5',
    light: '#6573c3',
    dark: '#002984',
    contrastText: '#ffffff',
  },
  secondary: {
    main: '#f50057',
    light: '#ff5370',
    dark: '#bb002f',
    contrastText: '#000000',
  },
  tertiary: {
    light: '#b0b0b0',
    main: '#7d7d7d',
    dark: '#4a4a4a',
    contrastText: '#ffffff',
  },
  error: {
    main: '#f44336',
    light: '#ff7961',
    dark: '#ba000d',
    contrastText: '#ffffff',
  },
  success: {
    main: '#4caf50',
    light: '#80e27e',
    dark: '#087f23',
    contrastText: '#000000',
  },
  warning: {
    main: '#ff9800',
    light: '#ffc947',
    dark: '#c66900',
    contrastText: '#ffffff',
  },
});
