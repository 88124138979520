import { gql } from '@apollo/client';

export const GET_PACKS = gql`
  query GET_PACKS {
    listAvailableOffers {
      packs {
        id
        title
        description
        price
        offers_include {
          title
          description
        }
      }
    }
  }
`;
