import client from '../../apollo-client';
import { Project, Sensor, ThresholdAlert } from '../../types/feelbat';
import {
  CREATE_PROJECT,
  FIND_ALL_PROJECT_SENSORS,
  FIND_ALL_USER_PROJECTS,
  FIND_PROJECT_BY_ID,
  FIND_PROJECT_THRESHOLD_HISTORY_BY_ID,
  GET_PROJECTS,
} from './projects.graphql';

export async function getProjectsService(): Promise<Project[]> {
  try {
    const { data } = await client.query({
      query: GET_PROJECTS,
    });

    return data.projects;
  } catch (error) {
    console.error('An error occurred while fetching projects:', error);
    throw error;
  }
}

export async function getProjectsByUserService(
  orderByField = 'created_at',
  orderByOrder = 'ASC'
): Promise<Project[]> {
  try {
    const { data } = await client.query({
      query: FIND_ALL_USER_PROJECTS,
      variables: {
        orderByField: orderByField,
        orderByOrder: orderByOrder,
      },
      fetchPolicy: 'no-cache', // Bypass the cache
    });

    return data.findAllUserProject;
  } catch (error) {
    console.error('An error occurred while fetching user projects:', error);
    throw error;
  }
}

export async function createProjectService(input: any): Promise<Project> {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_PROJECT,
      variables: {
        input: input,
      },
    });
    console.log(data);
    return data.createProject;
  } catch (error) {
    console.error('An error occurred while creating the project:', error);
    throw error;
  }
}

export async function findProjectByIdService(
  projectId: string,
  useNetwork: boolean = false
): Promise<Project | null> {
  try {
    const { data } = await client.query({
      query: FIND_PROJECT_BY_ID,
      variables: {
        projectId: projectId,
      },
      fetchPolicy: useNetwork ? 'network-only' : 'cache-first',
    });

    return data.findProjectUser;
  } catch (error) {
    console.error('An error occurred while fetching project by ID:', error);
    throw error;
  }
}

export async function findAllProjectSensorsService(
  projectId: string
): Promise<Sensor[] | null> {
  try {
    const { data } = await client.query({
      query: FIND_ALL_PROJECT_SENSORS,
      variables: {
        ProjectId: projectId,
      },
    });

    return data.findAllProjectSensor;
  } catch (error) {
    console.error(
      'An error occurred while fetching sensors for the project:',
      error
    );
    throw error;
  }
}

export interface fetchProjectThresholdHistoryByIdParams {
  projectId: string;
  from: number;
  to: number;
  filter: {
    page: number;
    pageSize: number;
    orderBy: string;
    sortOrder: string;
  };
}

export async function fetchProjectThresholdHistoryById({
  projectId,
  from,
  to,
  filter,
}: fetchProjectThresholdHistoryByIdParams): Promise<{
  thresholdHistory: { history: ThresholdAlert[]; count: number };
} | null> {
  try {
    const { data } = await client.query({
      query: FIND_PROJECT_THRESHOLD_HISTORY_BY_ID,
      variables: {
        thresholdHistoryInput: {
          projectId: projectId,
          from: from,
          to: to,
          filter,
        },
      },
    });
    return data;
  } catch (error) {
    console.error(
      'An error occurred while fetching project threshold history by ID:',
      error
    );
    throw error;
  }
}
