import React, { createContext, useContext, useState } from 'react';

type SelectedFilter = {
  orderByField: string;
  orderByOrder?: 'ASC' | 'DESC';
};

const FilterContext = createContext<{
  selectedFilter: SelectedFilter;
  setSelectedFilter: React.Dispatch<React.SetStateAction<SelectedFilter>>;
} | null>(null);

// export const FilterProvider: React.FC = ({ children }) => {
export const FilterProvider: React.FC<{
  children: React.ReactNode;
  defaultFilter?: SelectedFilter;
}> = ({ children, defaultFilter }) => {
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>(
    defaultFilter || {
      orderByField: 'created_at', // Default field
      orderByOrder: 'ASC', // Default order
    }
  );

  return (
    <FilterContext.Provider value={{ selectedFilter, setSelectedFilter }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilter = (customDefault?: SelectedFilter) => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useFilter must be used within a FilterProvider');
  }

  const { selectedFilter, setSelectedFilter } = context;

  // If custom default is provided and no filter is set, apply it.
  if (customDefault && !selectedFilter) {
    setSelectedFilter(customDefault);
  }

  return { selectedFilter, setSelectedFilter };
};
