import client from '../../apollo-client';
import { CreateReportInput } from '../../types/report';
import {
  CONSUME_REPORT,
  CREATE_REPORT,
  GENERATE_PDF,
  GET_ALL_CONFIGS_FOR_PROJECT,
  GET_PREVIEW_REPORT,
  SAVE_CONFIG_FOR_PROJECT,
  TEST_GENERATE_PDF,
} from './pdfReport.graphql';

export async function createReportService(input: any): Promise<Report> {
  try {
    const { data } = await client.mutate({
      mutation: CREATE_REPORT,
      variables: {
        input: input,
      },
    });

    return data.createReport;
  } catch (error) {
    console.error('An error occurred while creating the report:', error);
    throw error;
  }
}

/**
 * Generates a PDF report based on the provided input and optionally adds a watermark.
 *
 * @param {CreateReportInput} input - The input data used to create the report.
 * This should conform to the structure defined in the CreateReportInput type.
 * @param {boolean} [preview=false] - If true, a watermark will be added to the generated PDF.
 *
 * @returns {Promise<any>} - A promise that resolves to the generated PDF data, or rejects with an error.
 *
 * @throws {Error} - Throws an error if the PDF generation fails,
 * allowing the calling function to handle it appropriately.
 */
export async function generatePDFService(
  input: CreateReportInput,
  preview: boolean = false
): Promise<{
  pdf: string;
  token?: {
    access_token: string;
    refresh_token: string;
  };
}> {
  try {
    const { data } = await client.mutate({
      mutation: GENERATE_PDF,
      variables: {
        input: { ...input, watermark: preview },
      },
    });

    return data.generatePDFReport;
  } catch (error) {
    console.error('An error occurred while generating the report:', error);
    throw error;
  }
}

export async function getPreviewReportService(input: any): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: GET_PREVIEW_REPORT,
      variables: {
        input: input,
      },
    });

    return data.getPreviewReport;
  } catch (error) {
    console.error('An error occurred while getting the preview report:', error);
    throw error;
  }
}

/**
 * Fetches all templates/configurations for a given project.
 *
 * @param {object} input - Input object containing the projectId.
 * @param {string} input.projectId - The unique identifier of the project for which the templates/configs are to be fetched.
 * @returns {Promise<any>} - A promise that resolves to the list of configurations for the given project.
 *
 * @throws {Error} If there is an error while fetching the configurations.
 */
export async function getAllTemplates(input: {
  projectId: string;
}): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_ALL_CONFIGS_FOR_PROJECT,
      variables: input,
    });

    return data.getAllConfigsForProject;
  } catch (reason) {
    console.error('An error occurred while fetching configs:', reason);
    throw reason;
  }
}

/**
 * Saves a configuration for a given project.
 *
 * @param {object} input - The input object containing configuration details.
 * @param {string} [input.pdfProjectConfig] - Optional string representing the PDF project configuration.
 * @param {string} input.projectId - The unique identifier of the project for which the configuration is to be saved.
 * @param {string} input.title - The title of the configuration.
 * @param {CreateReportInput} input.config - The configuration details to be saved.
 * @returns {Promise<any>} - A promise that resolves to the data returned after saving the configuration.
 *
 * @throws {Error} If there is an error while saving the configuration.
 */
export async function saveConfigForProject({
  pdfProjectConfig,
  projectId,
  title,
  config,
}: {
  pdfProjectConfig?: string;
  projectId: string;
  title: string;
  config: CreateReportInput;
}): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: SAVE_CONFIG_FOR_PROJECT,
      variables: {
        pdfProjectConfig,
        projectId,
        title,
        config,
      },
    });

    return data;
  } catch (error) {
    console.error('An error occurred while getting the preview report:', error);
    throw error;
  }
}

export async function consumeReportService(): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: CONSUME_REPORT,
    });

    return data.consumeReport;
  } catch (error) {
    console.error('Ann error occured while consuming the report quota:', error);
    throw error;
  }
}

export async function testGeneratePDFService(): Promise<any> {
  try {
    const { data } = await client.mutate({
      mutation: TEST_GENERATE_PDF,
    });

    return data.testGeneratePDF;
  } catch (error) {
    console.error('An error occurred while generating the PDF:', error);
    throw error;
  }
}
