import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { styled, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { MaterialDesignContent, SnackbarProvider } from 'notistack';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app/app';
import client from './app/core/apollo-client';
import { persistor, store } from './app/core/store/store';
import i18n from './app/core/translations/i18n';
import './init';
import { lightTheme } from './theme';

Sentry.init({
  dsn: 'https://dd506f720ca01235b25e622184feea65@o4506789581684736.ingest.us.sentry.io/4506789583585280',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'dev.feelbat.tech',
    'staging.feelbat.tech',
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: ['staging', 'production'].includes(import.meta.env.MODE),
  environment: import.meta.env.MODE,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const darkMode = false;

const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  ({ theme }) => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    '&.notistack-MuiContent-error': {
      backgroundColor: 'theme.palette.error.main',
      color: theme.palette.error.contrastText,
    },
    '&.notistack-MuiContent-warning': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
    },
    '&.notistack-MuiContent-info': {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
    },
  })
);

root.render(
  <StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={lightTheme}>
        {/* CssBaseline makes your app adhere to Material UI styles */}
        <CssBaseline />
        <I18nextProvider i18n={i18n}>
          <Router>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  autoHideDuration={3000}
                  Components={{
                    default: StyledMaterialDesignContent,
                    success: StyledMaterialDesignContent,
                    warning: StyledMaterialDesignContent,
                    info: StyledMaterialDesignContent,
                    error: StyledMaterialDesignContent,
                  }}
                >
                  <App />
                </SnackbarProvider>
              </PersistGate>
            </Provider>
          </Router>
        </I18nextProvider>
      </ThemeProvider>
    </ApolloProvider>
  </StrictMode>
);
