import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginPayload } from '../../types/auth';
import { ServerError } from '../../types/error';
import { handleGraphQLError } from '../../utils/errorUtils';
import { RootState } from '../store';
import { loginService, refreshTokenService } from './auth.service';
import { consumeReportService } from '../pdfReport/pdfReport.service';

export const loginUser = createAsyncThunk<
  { access_token: string; refresh_token: string },
  LoginPayload,
  { rejectValue: ServerError; state: RootState }
>('auth/loginUser', async (userData: LoginPayload, thunkApi) => {
  try {
    const data = await loginService(userData);
    return data;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

export const refreshToken = createAsyncThunk<
  { access_token: string; refresh_token: string },
  { refreshToken: string },
  { rejectValue: { message: string } }
>('auth/refreshToken', async ({ refreshToken }, thunkApi) => {
  try {
    const data = await refreshTokenService(refreshToken);
    return data;
  } catch (err) {
    const error: Error =
      err instanceof Error ? err : new Error('An unknown error occurred.');

    return thunkApi.rejectWithValue({ message: error.message });
  }
});

export const consumeReport = createAsyncThunk<
  { access_token: string; refresh_token: string },
  void,
  { rejectValue: { message: string } }
>('auth/consumeReport', async (_, thunkApi) => {
  try {
    const data = await consumeReportService();
    return data;
  } catch (err) {
    const error: Error =
      err instanceof Error ? err : new Error('An unknown error occurred.');

    return thunkApi.rejectWithValue({ message: error.message });
  }
});
