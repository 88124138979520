import { LinearProgress, Theme, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import React, { useMemo } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { FilterProvider } from './core/context/FilterContext';
import useAuth from './core/hooks/useAuth';
import { routes } from './core/routes';
import { RoleIdEnum } from './core/types/auth';

// Styles
const AppContainer = styled('div')<{ theme: Theme }>`
  background: ${(props) => props.theme.custom.colors.tertiary.light};
  min-height: 100vh;
  height: 100%;
`;

// Utility functions
const ProtectedRoute = ({
  isAuthenticated,
  role,
  LazyComponent,
  requiredRoles,
}: {
  isAuthenticated: boolean;
  role: string | null;
  requiredRoles: RoleIdEnum[] | undefined;
  LazyComponent: React.ComponentType;
}) => {
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (
    requiredRoles !== undefined &&
    !requiredRoles.includes(role as RoleIdEnum)
  ) {
    return <Navigate to="/" replace />;
  }

  return (
    <React.Suspense fallback={<LinearProgress />}>
      <LazyComponent />
    </React.Suspense>
  );
};

const App = () => {
  const { isAuthenticated, isLoading, role } = useAuth();
  const theme = useTheme();

  const processedRoutes = useMemo(() => {
    return [
      ...routes.map(
        ({ path, element: LazyComponent, isProtected, requiredRoles }) => {
          return {
            path,
            element: isProtected ? (
              <ProtectedRoute
                isAuthenticated={isAuthenticated}
                role={role}
                requiredRoles={requiredRoles}
                LazyComponent={LazyComponent}
              />
            ) : (
              <React.Suspense fallback={<LinearProgress />}>
                <LazyComponent />
              </React.Suspense>
            ),
          };
        }
      ),
      { path: '/', element: <Navigate to="/projects" replace /> },
      {
        path: '*',
        element: isAuthenticated ? (
          <Navigate to="/projects" replace />
        ) : (
          <Navigate to="/login" replace />
        ),
      },
    ];
  }, [isAuthenticated]);

  const renderedRoutes = useRoutes(processedRoutes);

  return (
    <FilterProvider>
      <AppContainer theme={theme}>
        {isLoading && <LinearProgress />}
        {renderedRoutes}
      </AppContainer>
    </FilterProvider>
  );
};

export default App;
