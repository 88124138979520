import { colors } from '@mui/material';

/**
 * Represents the state of authentication.
 */
export interface AuthState {
  isLoading: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  userId: string | null;
  role: string | null;
}

/**
 * Represents the payload required for login.
 */
export interface LoginPayload {
  email: string;
  password: string;
}

/**
 * Enumeration for Role IDs.
 *
 * - `ADMIN`: Represents an admin role.
 * - `USER`: Represents a user role.
 * - `DEV`: Represents a developer role.
 * - `MARKETING`: Represents a marketing role.
 */
export enum RoleIdEnum {
  ADMIN = 'Admin',
  USER = 'User',
  DEV = 'Dev',
  MARKETING = 'Marketing',
}

/**
 * Record that maps role IDs to their respective color strings.
 *
 * - `ADMIN`: Blue color (shade 900).
 * - `USER`: Blue color (shade 500).
 * - `DEV`: Green color (shade 500).
 * - `MARKETING`: Orange color (shade 500).
 */
export const roleColor = {
  ADMIN: colors.blue[900],
  USER: colors.blue[500],
  DEV: colors.green[500],
  MARKETING: colors.orange[500],
};

/**
 * Function to get the color associated with a given role.
 *
 * @param role - The role for which to retrieve the color.
 * @returns The color string associated with the provided role.
 */
export const getRoleColor = (role: keyof typeof RoleIdEnum) => {
  return roleColor[role.toUpperCase() as keyof typeof roleColor];
};

/**
 * Function to get the color associated with a given pack title.
 *
 * @param packTitle - The title of the pack for which to retrieve the color.
 * @returns The color string associated with the provided pack title.
 */
export const getPackColor = (packTitle: string) => {
  switch (packTitle) {
    case 'feelexpert':
      return colors.red[700];
    case 'feelprems':
      return colors.purple[600];
    default:
      return colors.blue[600];
  }
};
