import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { logoutUser } from './store/auth/authSlice';
import { store } from './store/store';

const uri =
  import.meta.env.VITE_NODE_ENV === 'development'
    ? import.meta.env.VITE_NX_PROXY_SERVER
    : import.meta.env.VITE_NX_APP_PROXY_TARGET;

const httpLink = new HttpLink({
  uri,
  fetchOptions: {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    // Define upload limit in bytes
    size: 5000000, // Example limit: 5 MB
  },
});

// Global flag to indicate logout attempt
export let logoutAttempted = false;

// TODO: Need to add the mecanic for refreshing tokens
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    // eslint-disable-next-line array-callback-return
    graphQLErrors.map(({ message }) => {
      if (
        message.includes('jwt expired') ||
        message.includes('invalid_signature')
      ) {
        logoutAttempted = true;
        // JWT is expired, clear auth state and redirect to login
        store.dispatch(logoutUser());
        window.location.href = '/login';
      } else {
        console.log(`[GraphQL error]: Message: ${message}`);
      }
    });
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { accessToken } = store.getState().auth;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
