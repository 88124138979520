import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { GraphQLErrorMessage, ServerError } from '../types/error';
import { ERROR_CODES } from './errorCodes';

export const formatGraphQLError = (
  graphQLError: GraphQLError
): GraphQLErrorMessage => ({
  message: graphQLError.message,
  path: graphQLError.path || [],
  extensions: {
    code: (graphQLError.extensions?.code as string) || 'UNKNOWN_ERROR',
    stacktrace:
      (graphQLError.extensions?.stacktrace as (string | number)[]) || [],
    serviceName: (graphQLError.extensions?.serviceName as string) || '',
  },
});

class JwtExpiredError extends Error {}

export const handleGraphQLError = (err: unknown): ServerError => {
  const apolloError = err as ApolloError;

  if (apolloError.graphQLErrors && apolloError.graphQLErrors.length > 0) {
    const serverError: ServerError = {
      errors: apolloError.graphQLErrors.map(formatGraphQLError),
      data: null,
    };

    for (const error of serverError.errors) {
      if (error.message.includes('jwt expired')) {
        throw new JwtExpiredError();
      }
    }

    return serverError;
  } else {
    return {
      errors: [
        {
          message: 'An error occurred while processing the request.',
          path: [],
          extensions: {
            code: 'UNKNOWN_ERROR',
            stacktrace: [],
            serviceName: '',
          },
        },
      ],
      data: null,
    };
  }
};

export const transformServerErrorCode = (serverErrorCode: string) => {
  if (serverErrorCode in ERROR_CODES) {
    return ERROR_CODES[serverErrorCode as keyof typeof ERROR_CODES];
  }
  return ERROR_CODES.UNEXPECTED_ERROR;
};
