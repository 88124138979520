import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerError } from '../../types/error';
import { handleGraphQLError } from '../../utils/errorUtils';
import { RootState } from '../store';
import { getCurrentOffersService, getOffersService } from './offers.service';

export const getCurrentOffers = createAsyncThunk<
  any,
  { userId: string },
  { rejectValue: ServerError; state: RootState }
>('offers/getCurrentOffers', async (getCurrentOffersInput, thunkApi) => {
  try {
    const findCurrentOffers = await getCurrentOffersService(
      getCurrentOffersInput.userId
    );
    return findCurrentOffers;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

export const fetchOffers = createAsyncThunk<
  any,
  void,
  { rejectValue: ServerError; state: RootState }
>('offers/fetchOffers', async (_, thunkApi) => {
  try {
    const findOffers = await getOffersService();
    return findOffers;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});
