import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ERROR_CODES } from '../../utils/errorCodes';
import { fetchPacks } from './packs.thunk';

interface OffersState {
  isLoading: boolean;
  errorCode: keyof typeof ERROR_CODES | string | null;
  packs: any | null;
}

const initialState: OffersState = {
  isLoading: false,
  errorCode: null,
  packs: null,
};

const packsSlice = createSlice({
  name: 'packs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPacks.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(fetchPacks.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.packs = action.payload;
      })
      .addCase(fetchPacks.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
      });
  },
});

export default packsSlice.reducer;
