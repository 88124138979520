import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerError } from '../../types/error';
import { handleGraphQLError } from '../../utils/errorUtils';
import { RootState } from '../store';
import {
  fetchProjectThresholdHistoryById,
  fetchProjectThresholdHistoryByIdParams,
  findAllProjectSensorsService,
  findProjectByIdService,
  getProjectsByUserService,
  getProjectsService,
} from './projects.service';
import { Project, Sensor, ThresholdAlert } from '../../types/feelbat';

export const fetchProjects = createAsyncThunk<
  Project[],
  void, // No payload required for fetching projects
  { rejectValue: ServerError; state: RootState }
>('projects/fetchProjects', async (_, thunkApi) => {
  try {
    const projects = await getProjectsService();
    return projects;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

export const fetchProjectsByUser = createAsyncThunk<
  Project[],
  { orderByField: string; orderByOrder: string },
  { rejectValue: ServerError; state: RootState }
>('projects/fetchProjectsByUser', async (params, thunkApi) => {
  try {
    const projects = await getProjectsByUserService(
      params.orderByField,
      params.orderByOrder
    );
    return projects;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});

export const fetchProjectById = createAsyncThunk<Project, string>(
  'projects/fetchProjectById',
  async (projectId, thunkApi) => {
    try {
      const project = await findProjectByIdService(projectId);

      if (project) {
        return project;
      } else {
        throw new Error('Project not found');
      }
    } catch (err) {
      const serverError = await handleGraphQLError(err);
      return thunkApi.rejectWithValue(serverError);
    }
  }
);

export const fetchAllProjectSensors = createAsyncThunk<Sensor[], string>(
  'sensors/fetchAllProjectSensors',
  async (projectId, thunkApi) => {
    try {
      const sensors = await findAllProjectSensorsService(projectId);
      if (sensors) {
        return sensors;
      } else {
        throw new Error('Sensors not found for the project');
      }
    } catch (err) {
      const serverError = await handleGraphQLError(err);
      return thunkApi.rejectWithValue(serverError);
    }
  }
);

/**
 * Fetches the threshold history for a project.
 *
 * @param {string} projectId - The ID of the project for which to fetch the threshold history.
 * @param {AsyncThunkApiConfig} thunkApi - The Thunk API configuration.
 *
 * @returns {Promise<ThresholdAlert[]>} A Promise that resolves to an array of ThresholdAlert objects.
 * @throws {Error} If the threshold history is not found.
 */
export const fetchProjectThresholdHistory = createAsyncThunk<
  {
    thresholdHistory: { history: ThresholdAlert[]; count: number };
  },
  fetchProjectThresholdHistoryByIdParams
>('projects/fetchProjectThresholdHistory', async (params, thunkApi) => {
  try {
    const data = await fetchProjectThresholdHistoryById(params);

    if (data) {
      return data;
    } else {
      throw new Error('Threshold history not found');
    }
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});