import { gql } from '@apollo/client';

export const GET_USER = gql`
  query getUserById($id: String!) {
    user(id: $id) {
      id
      created_at
      updated_at
      first_name
      last_name
      email
      phone_number
      failed_login_attempts
      is_locked
      email_verified
      last_login
      offers_id
      offer_exp
      token_linkfeel
      token_linkfeel_used
      role
      country
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(updateUserInput: $updateUserInput) {
      id
      created_at
      updated_at
      first_name
      last_name
      email
      phone_number
      failed_login_attempts
      is_locked
      email_verified
      last_login
      offers_id
      offer_exp
      token_linkfeel
      token_linkfeel_used
      role
      country
    }
  }
`;

export const ALL_USERS = gql`
  query AllUsers {
    getAllUsersWithActivePack {
      id
      first_name
      last_name
      email
      token_linkfeel
      token_linkfeel_used
      role
      active_pack {
        subscribed_at
        subscribe_end
        price
        title
        offers_include {
          title
          price
        }
      }
    }
  }
`;

export const REMOVE_USER = gql`
  mutation removeUser($id: String!) {
    softDeleteUser(id: $id)
  }
`;
