import client from '../../apollo-client';
import { LoginPayload } from '../../types/auth';
import {
  CONFIRM_EMAIL,
  FORGOT_PASSWORD_REQUEST,
  LOGIN_USER,
  REFRESH_TOKEN,
  RESET_PASSWORD,
} from './auth.graphql';

export async function loginService(userData: LoginPayload) {
  try {
    const { data } = await client.mutate({
      mutation: LOGIN_USER,
      variables: userData,
    });

    const { access_token, refresh_token } = data.login;

    return { access_token, refresh_token };
  } catch (error) {
    console.error('An error occurred during login:', error);
    throw error;
  }
}

export async function refreshTokenService(refreshToken: string) {
  try {
    const { data } = await client.mutate({
      mutation: REFRESH_TOKEN,
      variables: { refreshToken },
    });

    const newAccessToken = data.refresh;

    return newAccessToken;
  } catch (error) {
    console.error('An error occurred during token refresh:', error);
    throw new Error('Token refresh failed. Please log in again.');
  }
}

export async function confirmEmailService(emailConfirmationToken: string) {
  try {
    const { data } = await client.mutate({
      mutation: CONFIRM_EMAIL,
      variables: { emailConfirmationToken },
    });

    const confirmationResult = data.confirmEmail;

    return confirmationResult;
  } catch (error) {
    console.error('An error occurred during email confirmation:', error);
    throw error;
  }
}

export async function forgotPasswordRequestService(
  email: string
): Promise<string> {
  try {
    const { data } = await client.mutate({
      mutation: FORGOT_PASSWORD_REQUEST,
      variables: { email },
    });

    const requestResult = data.forgotPasswordRequest;

    return requestResult;
  } catch (error) {
    console.error('An error occurred during forgot password request:', error);
    throw error;
  }
}

export async function resetPasswordService(
  resetPasswordToken: string,
  password: string,
  id: string
): Promise<string> {
  const { data } = await client.mutate({
    mutation: RESET_PASSWORD,
    variables: { resetPasswordToken, updateUserInput: { id, password } },
  });

  const resetResult = data.resetPassword;

  return resetResult;
}
