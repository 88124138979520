import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Project, ProjectWithCounts } from '../../types/feelbat';
import { ERROR_CODES } from '../../utils/errorCodes';
import { getTModuleAndSensorsCount } from '../../utils/misc';
import { fetchProjectById, fetchProjectsByUser } from './projects.thunk';

interface ProjectsState {
  isLoading: boolean;
  errorCode: keyof typeof ERROR_CODES | string | null;
  projects: ProjectWithCounts[];
  selectedProject: Project | null;
}

const initialState: ProjectsState = {
  isLoading: false,
  errorCode: null,
  projects: [],
  selectedProject: null,
};

const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    clearProjectsError: (state) => {
      state.errorCode = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectsByUser?.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchProjectsByUser?.fulfilled,
        (state, action: PayloadAction<Project[]>) => {
          state.isLoading = false;
          const projectsWithCounts = getTModuleAndSensorsCount(action.payload);
          state.projects = projectsWithCounts;
        }
      )
      .addCase(fetchProjectsByUser?.rejected, (state, action) => {
        state.isLoading = false;

        if (action.payload) {
          const serverErrorCode = action.payload.errors[0]?.extensions?.code;
          state.errorCode = serverErrorCode || 'UNEXPECTED_ERROR';
        } else {
          state.errorCode = 'UNEXPECTED_ERROR';
        }

        state.projects = [];
      })

      .addCase(
        fetchProjectById?.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.selectedProject = action.payload;
        }
      )

      .addCase(fetchProjectById?.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(fetchProjectById?.rejected, (state, action) => {
        state.isLoading = false;
        state.errorCode = action.error.message || 'UNEXPECTED_ERROR';
      });
  },
});

export const { clearProjectsError } = projectsSlice.actions;

export default projectsSlice.reducer;
