import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { updateTemperatureLines } from '../utils';
import { Module, SelectedSensor } from './types';
import { MeasureTypeEnum, SensorFamilyEnum } from '../../enums/feelbat.enum';
import { TModule } from '../../types/feelbat';

export interface Comparison {
  id: string | number;
  selectedFamily: SensorFamilyEnum;
  selectedModules: {
    moduleId: string;
    selectedSensors: SelectedSensor[];
  }[];
}

export interface SelectedSensorLine {
  sensorId: string;
  line: string;
}

interface PdfReportState {
  selectedSensors: SelectedSensor[];
  selectedComparisonSensors: SelectedSensor[];
  sensorChartFormattedModules: Module[];
  uncheckTemperatureModules: string[];
  projectPlans: string[];
  comparisons: Comparison[];
  preprocessedModules: TModule[];
  selectedSensorLines: SelectedSensorLine[];
}

const initialState: PdfReportState = {
  selectedSensors: [],
  selectedComparisonSensors: [],
  uncheckTemperatureModules: [],
  sensorChartFormattedModules: [],
  projectPlans: [],
  comparisons: [],
  preprocessedModules: [],
  selectedSensorLines: [],
};

const pdfReportSlice = createSlice({
  name: 'pdfReport',
  initialState,
  reducers: {
    setSelectedSensors: (state, action: PayloadAction<SelectedSensor[]>) => {
      state.selectedSensors = action.payload;
    },
    addSelectedSensor: (state, action: PayloadAction<SelectedSensor>) => {
      state.selectedSensors.push(action.payload);
    },
    removeSelectedSensor: (state, action: PayloadAction<string>) => {
      state.selectedSensors = state.selectedSensors.filter(
        (sensor) => sensor.sensorId !== action.payload
      );
    },
    toggleCheckStateOfTemperatureByModules: (
      state,
      action: PayloadAction<string>
    ) => {
      const moduleId = action.payload;
      if (state.uncheckTemperatureModules?.includes(moduleId)) {
        state.uncheckTemperatureModules =
          state.uncheckTemperatureModules?.filter((id) => id !== moduleId);
      } else {
        state.uncheckTemperatureModules?.push(moduleId);
      }
    },
    updatedCheckedStateForTemperatureLinesByModule: (state) => {
      state.selectedSensors = updateTemperatureLines(
        state.selectedSensors,
        state.uncheckTemperatureModules
      );
    },

    setSensorChartFormattedModules: (
      state,
      action: PayloadAction<Module[]>
    ) => {
      state.sensorChartFormattedModules = action.payload;
    },
    setProjectPlans(state, action: PayloadAction<any[]>) {
      state.projectPlans = action.payload;
    },
    resetPdfReportState: (state) => {
      state.selectedSensors = [];
      state.sensorChartFormattedModules = [];
      state.uncheckTemperatureModules = [];
      state.projectPlans = [];
      state.preprocessedModules = [];
      state.comparisons = [];
      state.selectedSensorLines = [];
    },
    setPreprocessedModules: (state, action: PayloadAction<TModule[]>) => {
      state.preprocessedModules = action.payload;
    },
    setSelectedComparisonSensor: (
      state,
      action: PayloadAction<SelectedSensor[]>
    ) => {
      state.selectedComparisonSensors = action.payload;
    },
    addSelectedComparisonSensor: (
      state,
      action: PayloadAction<SelectedSensor>
    ) => {
      state.selectedComparisonSensors.push(action.payload);
    },
    removeSelectedComparisonSensor: (state, action: PayloadAction<string>) => {
      state.selectedComparisonSensors = state.selectedSensors.filter(
        (sensor) => sensor.sensorId !== action.payload
      );
    },
    addComparison: (state, action: PayloadAction<Comparison>) => {
      state.comparisons.push(action.payload);
    },
    updateComparison: (state, action: PayloadAction<Comparison>) => {
      const index = state.comparisons.findIndex(
        (c) => c.id === action.payload.id
      );
      if (index > -1) {
        state.comparisons[index] = action.payload;
      }
    },
    removeComparison: (state, action: PayloadAction<string>) => {
      state.comparisons = state.comparisons.filter(
        (c) => c.id !== action.payload
      );
    },
    setComparisons: (state, action: PayloadAction<Comparison[]>) => {
      state.comparisons = action.payload;
    },
    selectSensorLine: (state, action: PayloadAction<SelectedSensorLine>) => {
      state.selectedSensorLines = [
        ...state.selectedSensorLines,
        action.payload,
      ];
    },
    deselectSensorLine: (state, action: PayloadAction<SelectedSensorLine>) => {
      state.selectedSensorLines = state.selectedSensorLines?.filter(
        (item) =>
          !(
            item.sensorId === action.payload.sensorId &&
            item.line === action.payload.line
          )
      );
    },
    resetSelected: (state) => {
      state.selectedSensorLines = [];
    },
    selectAllTemperatureLines: (
      state,
      action: PayloadAction<{ moduleId: string; family: string }>
    ) => {
      const { moduleId, family } = action.payload;
      const targetModule = state.preprocessedModules.find(
        (mod) => mod.id === moduleId
      );
      if (targetModule) {
        targetModule.sensors.forEach((sensor) => {
          if (
            sensor.family === family &&
            sensor.lines?.includes(MeasureTypeEnum.TEMPERATURE)
          ) {
            state.selectedSensorLines.push({
              sensorId: sensor.id,
              line: MeasureTypeEnum.TEMPERATURE,
            });
          }
        });
      }
    },
    deselectAllTemperatureLines: (
      state,
      action: PayloadAction<{ moduleId: string; family: string }>
    ) => {
      const { moduleId, family } = action.payload;
      state.selectedSensorLines = state.selectedSensorLines.filter((item) => {
        const sensor = state.preprocessedModules
          .find((mod) => mod.id === moduleId)
          ?.sensors.find((s) => s.id === item.sensorId);
        return !(
          sensor?.family === family && item.line === MeasureTypeEnum.TEMPERATURE
        );
      });
    },
    saveComparison: (state, action: PayloadAction<Comparison>) => {
      const index = state.comparisons.findIndex(
        (c) => c.id === action.payload.id
      );
      if (index !== -1) {
        state.comparisons[index] = action.payload;
      } else {
        state.comparisons.push(action.payload);
      }
    },
  },
});

export const {
  setSelectedSensors,
  addSelectedSensor,
  removeSelectedSensor,
  toggleCheckStateOfTemperatureByModules,
  updatedCheckedStateForTemperatureLinesByModule,
  setSensorChartFormattedModules,
  setProjectPlans,
  resetPdfReportState,
  setPreprocessedModules,
  selectSensorLine,
  deselectSensorLine,
  resetSelected,
  deselectAllTemperatureLines,
  selectAllTemperatureLines,
  saveComparison,
  updateComparison,
  setSelectedComparisonSensor,
  addSelectedComparisonSensor,
  removeSelectedComparisonSensor,
} = pdfReportSlice.actions;

export default pdfReportSlice.reducer;
