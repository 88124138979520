import client from '../../apollo-client';
import { GET_OFFERS, GET_CURRENT_OFFERS } from './offers.graphql';

export async function getCurrentOffersService(
  userId: string
): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_CURRENT_OFFERS,
      variables: {
        user_id: userId
      },
    });

    return data.listSubscribedOffers;
  } catch (error) {
    console.error('An error occurred while fetching current offers:', error);
    throw error;
  }
}

export async function getOffersService(): Promise<any> {
  try {
    const { data } = await client.query({
      query: GET_OFFERS,
    });

    return data.listAvailableOffers.offers;
  } catch (error) {
    console.error('An error occurred while fetching offers:', error);
    throw error;
  }
}
