import { createAsyncThunk } from '@reduxjs/toolkit';
import { ServerError } from '../../types/error';
import { handleGraphQLError } from '../../utils/errorUtils';
import { RootState } from '../store';
import { getPacksService } from './packs.service';

export const fetchPacks = createAsyncThunk<
  any,
  void,
  { rejectValue: ServerError; state: RootState }
>('packs/fetchPacks', async (_, thunkApi) => {
  try {
    const findPacks = await getPacksService();
    return findPacks;
  } catch (err) {
    const serverError = await handleGraphQLError(err);
    return thunkApi.rejectWithValue(serverError);
  }
});
