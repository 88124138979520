import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ERROR_CODES } from '../../utils/errorCodes';
import {
  fetchMetricsBySensorId,
  fetchSensorById,
  fetchSensorCountByFamily,
  fetchAllUserSensor,
  fetchSensorsInStockAndProject,
  updateSensor,
  fetchMetricsBySensorsIdList,
} from './sensors.thunk';

interface MetricDetail {
  value: number;
  time: string;
  delta: number;
}

export interface MetricField {
  field: string;
  VStartBrut: number;
  VLastBrut: number;
  VLastDelta: number;
  metrics: MetricDetail[];
  color?: string;
}

export interface MetricsById {
  sensor_id: string;
  measurement: string;
  metrics: MetricField[];
}

interface SensorsState {
  isLoading: boolean;
  errorCode: keyof typeof ERROR_CODES | string | null;
  sensorCountByFamily: any | null;
  sensorsInStockAndProject: any | null;
  selectedSensor: any;
  sensorMetricsById: any | null;
  metricsOfSelectedSensor: MetricsById | null;
  metricsBySensorsIdList: MetricsById[] | null;
  selectedSensorIds: string[];
  apiDateRange: [string, string] | null;
  sigfoxSensors: any | null;
}

const initialState: SensorsState = {
  isLoading: false,
  errorCode: null,
  sensorCountByFamily: null,
  sensorsInStockAndProject: null,
  selectedSensor: null,
  sensorMetricsById: null,
  metricsOfSelectedSensor: null,
  metricsBySensorsIdList: null,
  selectedSensorIds: [],
  apiDateRange: [new Date().toISOString(), new Date().toISOString()],
  sigfoxSensors: null
};

const sensorsSlice = createSlice({
  name: 'sensors',
  initialState,
  reducers: {
    clearSensorsError: (state) => {
      state.errorCode = null;
    },
    setSelectedSensorIds: (state, action: PayloadAction<string[]>) => {
      state.selectedSensorIds = action.payload;
    },
    updateApiDateRange: (
      state,
      action: PayloadAction<[string, string] | null>
    ) => {
      state.apiDateRange = action.payload;
    },
    resetSensorData: (state) => {
      state.metricsBySensorsIdList = null;
      state.selectedSensorIds = [];
      state.apiDateRange = [new Date().toISOString(), new Date().toISOString()];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSensorCountByFamily.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchSensorCountByFamily.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.sensorCountByFamily = action.payload;
        }
      )
      .addCase(fetchSensorCountByFamily.rejected, (state, action) => {
        state.isLoading = false;
        state.errorCode = action.error.message || 'UNEXPECTED_ERROR';
      })
      .addCase(fetchAllUserSensor.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchAllUserSensor.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.sigfoxSensors = action.payload;
        }
      )
      .addCase(
        fetchAllUserSensor.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
        }
      )
      .addCase(fetchSensorsInStockAndProject.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchSensorsInStockAndProject.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.sensorsInStockAndProject = action.payload;
        }
      )
      .addCase(
        fetchSensorsInStockAndProject.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
        }
      )
      .addCase(updateSensor.pending, (state) => {
        state.errorCode = null;
      })
      .addCase(updateSensor.fulfilled, (state, action: PayloadAction<any>) => {
        // Extract updated sensor ID
        const updatedSensorId = action.payload.id;

        // Find index of the sensor to update
        const indexToUpdate =
          state.sensorsInStockAndProject?.inProject?.findIndex(
            (sensor: any) => sensor.id === updatedSensorId
          );

        // Replace old sensor with new one
        if (indexToUpdate !== -1 && state.sensorsInStockAndProject) {
          state.sensorsInStockAndProject.inProject[indexToUpdate] =
            action.payload;
        }
      })
      .addCase(updateSensor.rejected, (state, action: PayloadAction<any>) => {
        state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
      })
      .addCase(fetchSensorById.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchSensorById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.selectedSensor = action.payload;
        }
      )
      .addCase(fetchSensorById.rejected, (state, action) => {
        state.isLoading = false;
        state.errorCode = action.error.message || 'UNEXPECTED_ERROR';
      })
      .addCase(fetchMetricsBySensorId.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchMetricsBySensorId.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.metricsOfSelectedSensor = action.payload;
        }
      )
      .addCase(
        fetchMetricsBySensorId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
        }
      )
      .addCase(fetchMetricsBySensorsIdList.pending, (state) => {
        state.isLoading = true;
        state.errorCode = null;
      })
      .addCase(
        fetchMetricsBySensorsIdList.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.metricsBySensorsIdList = action.payload;
        }
      )
      .addCase(
        fetchMetricsBySensorsIdList.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.errorCode = action.payload?.errorCode || 'UNKNOWN_ERROR';
        }
      );
  },
});

export const {
  clearSensorsError,
  setSelectedSensorIds,
  updateApiDateRange,
  resetSensorData,
} = sensorsSlice.actions;

export default sensorsSlice.reducer;
