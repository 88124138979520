import { gql } from '@apollo/client';

export const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(loginInput: { email: $email, password: $password }) {
      access_token
      refresh_token
    }
  }
`;

export const REFRESH_TOKEN = gql`
  mutation refreshToken($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      access_token
      refresh_token
    }
  }
`;

export const CONFIRM_EMAIL = gql`
  mutation confirmEmail($emailConfirmationToken: String!) {
    confirmEmail(emailConfirmationToken: $emailConfirmationToken)
  }
`;

export const FORGOT_PASSWORD_REQUEST = gql`
  mutation forgotPasswordRequest($email: String!) {
    forgotPasswordRequest(email: $email)
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $resetPasswordToken: String!
    $updateUserInput: UpdateUserInput!
  ) {
    resetPassword(
      resetPasswordToken: $resetPasswordToken
      updateUserInput: $updateUserInput
    )
  }
`;

export const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(createUserInput: $createUserInput) {
      id
      first_name
      last_name
      email
      country
    }
  }
`;
